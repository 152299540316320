import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

// Import images for carousels
import antiSlip1 from './images/antislip1.webp';
import antiSlip2 from './images/antislip2.webp';
import antiSlip3 from './images/antislip3.webp';
import chemicalResistant1 from './images/chemicalresistant1.webp';
import chemicalResistant2 from './images/chemicalresistant2.webp';
import chemicalResistant3 from './images/chemicalresistant3.webp';
import highTraffic1 from './images/hightraffic1.webp';
import highTraffic2 from './images/hightraffic2.webp';
import highTraffic3 from './images/hightraffic3.webp';

// Styled Components for Commercial Page
const CommercialContainer = styled.section`
  padding: 100px 20px 40px 20px;
  background-color: #f9f9f9;
  color: #0f4c81;
  text-align: center;
`;
const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        arrows: false,
      },
    },
  ],
};

const CommercialHeading = styled.h2`
  font-size: 2.8rem;
  margin-bottom: 30px;
`;

const CommercialSubheading = styled.p`
  font-size: 1.3rem;
  margin-bottom: 50px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const SectionHeading = styled.h3`
  font-size: 2rem;
  margin-bottom: 15px;
  color: #0f4c81;
`;

const SectionDescription = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
  max-width: 750px;
  margin: 0 auto;
  line-height: 1.6;
`;

const CarouselWrapper = styled.div`
  width: 90vw;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;

  .slick-slide img {
    margin: auto;
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

// CTA Section
const CallToActionSection = styled.section`
  background-color: #0f4c81;
  color: white;
  padding: 50px 20px;
  text-align: center;
  margin-top: 50px;
`;

const CallToActionHeading = styled.h3`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const CallToActionText = styled.p`
  font-size: 1.3rem;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const ContactInfo = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #f4f4f4;
`;

// Commercial Component
const Commercial = () => {
  return (
    <CommercialContainer>
      <CommercialHeading>Commercial Epoxy Solutions</CommercialHeading>
      <CommercialSubheading>
        NEXT LEVEL EPOXY FLOORING provides industry-leading commercial flooring solutions that prioritize safety, durability, and aesthetics. Whether you need flooring for industrial warehouses, retail stores, restaurants, or office spaces, our epoxy coatings offer unmatched strength and visual appeal.
      </CommercialSubheading>

      {/* Anti-Slip Epoxy Section */}
      <SectionHeading>Anti-Slip Epoxy Flooring</SectionHeading>
      <SectionDescription>
        Ensure the safety of your employees and clients with anti-slip epoxy flooring. Our specially formulated coatings provide a durable, textured surface that reduces the risk of slips and falls, making it ideal for kitchens, gyms, and industrial environments.
      </SectionDescription>
      <CarouselWrapper>
        <Slider {...carouselSettings}>
          {[antiSlip1, antiSlip2, antiSlip3].map((img, idx) => (
            <div key={idx}><img src={img} alt={`Anti-Slip Epoxy ${idx + 1}`} /></div>
          ))}
        </Slider>
      </CarouselWrapper>

      {/* Chemical-Resistant Epoxy Section */}
      <SectionHeading>Chemical-Resistant Epoxy Flooring</SectionHeading>
      <SectionDescription>
        Protect surfaces from harsh chemicals with our chemical-resistant epoxy flooring. Ideal for laboratories, automotive shops, and industrial facilities, these floors resist corrosion, spills, and staining while maintaining a seamless and easy-to-clean surface.
      </SectionDescription>
      <CarouselWrapper>
        <Slider {...carouselSettings}>
          {[chemicalResistant1, chemicalResistant2, chemicalResistant3].map((img, idx) => (
            <div key={idx}><img src={img} alt={`Chemical-Resistant Epoxy ${idx + 1}`} /></div>
          ))}
        </Slider>
      </CarouselWrapper>

      {/* High-Traffic Area Epoxy Section */}
      <SectionHeading>High-Traffic Area Epoxy Flooring</SectionHeading>
      <SectionDescription>
        Designed to withstand heavy foot traffic in commercial spaces, our high-traffic epoxy flooring solutions provide maximum durability while enhancing the professional look of your business. These floors resist wear, tear, and fading, making them perfect for warehouses, shopping centers, and high-traffic public areas.
      </SectionDescription>
      <CarouselWrapper>
        <Slider {...carouselSettings}>
          {[highTraffic1, highTraffic2, highTraffic3].map((img, idx) => (
            <div key={idx}><img src={img} alt={`High-Traffic Epoxy ${idx + 1}`} /></div>
          ))}
        </Slider>
      </CarouselWrapper>

      {/* Call to Action Section */}
      <CallToActionSection>
        <CallToActionHeading>Contact Us Today!</CallToActionHeading>
        <CallToActionText>
          Need a custom epoxy flooring solution for your business? Call us at <strong>505-352-4674</strong> or email <strong>nextlevelepoxycoatings@gmail.com</strong>.
        </CallToActionText>
        <ContactInfo>Phone: 505-352-4674 | Email: nextlevelepoxycoatings@gmail.com</ContactInfo>
      </CallToActionSection>
    </CommercialContainer>
  );
};

export default Commercial;
