import React, { useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import flakeDiagram from './images/epoxydiagram1.webp';


// Example epoxy images (replace with your actual images)
import epoxy1 from './images/epoxyexample1.webp';
import epoxy3 from './images/epoxyexample3.webp';

// Import slick-carousel CSS for proper styling of dots/arrows
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InfoSection = styled.section`
  background-color: white;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  
`;

const MainContainer = styled.div`
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

  }
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom:-120px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;

  }
`;

const InfoImage = styled.img`
  width: 48%;
  height: 400px;
  object-fit: contain;
  border-radius: 8px;
  
  @media (max-width: 768px) {
    width: 100%;
    height: auto;

  }
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 25rem;
  height: 450px;
  border-radius: 8px;
  overflow: visible; /* Allow dots to be visible outside the image */
  margin-top: 60px;
  /* Position and style the slick dots */
  .slick-dots {
    position: absolute;
    bottom: -24px; /* Moved down 5 more px */
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    margin: 0;
    padding: 0;
  }

  /* Make the dots smaller */
  .slick-dots li button:before {
    color: #0f4c81;
    font-size: 7px;
  }

  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 300px; /* Adjust height on mobile */
      margin-top: 0px;
  }
`;

const InfoText = styled.p`
  font-size: 1.2rem;
  max-width: 80vw;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 20px;
      @media (max-width: 768px) {
      margin-top: -10px;

  }
   
`;

const InfoHeading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 0px;
  margin-top: 40px;
  color: #0f4c81;
  text-align: center;
  width: 80vw;
    @media (max-width: 768px) {
      margin-bottom: -10px;

  }
`;

// Slider settings with appendDots for extra control over the dot container
const settings = {
  dots: true,
  appendDots: dots => <ul style={{ margin: "0px" }}>{dots}</ul>,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: true,
  fade: false,
};

const EpoxyInfo = () => {
  const sliderRef = useRef(null);

  // Handler to determine tap position and move slides accordingly
  const handleSliderClick = (e) => {
    // If clicking on the dots, let them handle the event
    if (e.target.closest('.slick-dots')) return;
    const sliderRect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - sliderRect.left;
    const halfWidth = sliderRect.width / 2;
    if (clickX < halfWidth) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  };

  return (
    <InfoSection>
      <InfoHeading>Our Floors Last A Lifetime. Guaranteed.</InfoHeading>

      <MainContainer>
        <InfoContainer>
          {/* Flake Diagram Image */}
          <InfoImage src={flakeDiagram} alt="Flake Diagram Showing Epoxy System" />

          {/* Epoxy Example Slideshow */}
          <SliderWrapper onClick={handleSliderClick}>
            <Slider ref={sliderRef} {...settings}>
              <div>
                <img src={epoxy1} alt="Epoxy Example 1" />
              </div>
              <div>
                <img src={epoxy3} alt="Epoxy Example 2" />
              </div>
            </Slider>
          </SliderWrapper>
        </InfoContainer>

        {/* Detailed Description of the Epoxy Process */}
        <InfoText>
          At Next Level Epoxy Flooring, we use an advanced epoxy system that ensures durability, longevity, and beauty. Our process includes the following key elements:
        </InfoText>

        <InfoText>
          <strong>1. Surface Preparation:</strong> We begin by thoroughly preparing the concrete surface, which is critical for ensuring the epoxy adheres properly. This includes grinding the concrete to remove any surface imperfections, opening the pores of the concrete, and creating the ideal texture for epoxy application.
        </InfoText>

        <InfoText>
          <strong>2. Epoxy Base Layer:</strong> A thick layer of high-quality, 100% solid epoxy is applied to the prepared surface. This forms the foundation of our system, providing excellent adhesion and durability. Our epoxy resists chemicals, impacts, and abrasion, making it ideal for heavy traffic areas.
        </InfoText>

        <InfoText>
          <strong>3. Full Broadcast Vinyl Flake System:</strong> While the epoxy is still wet, we broadcast decorative vinyl flakes across the entire surface to create a beautiful and textured finish. These flakes add color, texture, and durability, ensuring a slip-resistant surface that looks amazing in any setting.
        </InfoText>

        <InfoText>
          <strong>4. Polyaspartic Clear Topcoat:</strong> To protect and seal the system, we apply a clear polyaspartic layer on top of the vinyl flakes. Polyaspartic is a high-performance, UV-resistant coating that prevents yellowing and provides exceptional durability. This topcoat not only protects the underlying layers but also adds a glossy, professional finish.
        </InfoText>

        <InfoText>
          This combination of high-quality materials and precise application techniques results in an epoxy flooring system that is built to last, resistant to chemicals, UV damage, and wear, making it perfect for both residential and commercial applications. Our commitment to quality ensures that your epoxy floor will maintain its beauty and functionality for years to come.
        </InfoText>
      </MainContainer>
    </InfoSection>
  );
};

export default EpoxyInfo;
